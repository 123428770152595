import { glasConfig } from "../constants/Config"
import request from 'axios'
import { GET_ALL_HISTORY_RANKING_EVENT, GET_ALL_RANKING_EVENT, GET_ALL_RANKING_EVENT_ANNOUNCED, GET_EVENT_SCHEDULE, REMOVE_EVENT_ANNOUNCED, UPDATE_CALL_PARAMS, UPDATE_RANKING_EVENT, UPDATE_RANKING_EVENT_ANNOUNCED } from "../constants/ActionTypes"
import moment from "moment"

const resultSuccess = (type, data) => {
    return {
        type: type,
        data,
    }
}

export const getAllRankingEvent = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token')
        const url = glasConfig.url_base + glasConfig.get_all_event_ranking

        const options = {
            method: 'GET',
            url: url,
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            json: true,
        }
        return request(options)
            .then((response) => {
                if (response.data.code === 0) {
                    const data = response.data.data
                    dispatch(resultSuccess(GET_ALL_RANKING_EVENT, data))
                } else {
                    throw 'システムエラー'
                }
            })
            .catch((error) => {
                throw error
            })
    }
}


export const addTellerEntry = (eventID, callback) => {
    return (dispatch) => {
        const token = localStorage.getItem('token')
        const url = glasConfig.url_base + glasConfig.add_teller_entry
        const params = {
            rankingEventId: eventID
        }
        const options = {
            method: 'POST',
            url: url,
            data: params,
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            json: true,
        }
        return request(options)
            .then((response) => {
                if (response.data.code === 0) {
                    dispatch(resultSuccess(UPDATE_RANKING_EVENT, eventID))
                    callback()
                } else {
                    throw 'システムエラー'
                }
            })
            .catch((error) => {
                throw error
            })
    }
}

export const getAllRankingEventAnnounced = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token')
        const url = glasConfig.url_base + glasConfig.get_all_ranking_announced

        const options = {
            method: 'GET',
            url: url,
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            json: true,
        }
        return request(options)
            .then((response) => {
                if (response.data.code === 0) {
                    const data = response.data.data
                    dispatch(resultSuccess(GET_ALL_RANKING_EVENT_ANNOUNCED, data))
                } else {
                    throw 'システムエラー'
                }
            })
            .catch((error) => {
                throw error
            })
    }
}

export const updateShowPopupAnnounced = (eventID, callback) => {
    return (dispatch) => {
        const token = localStorage.getItem('token')
        const url = glasConfig.url_base + glasConfig.update_show_popoup_announced
        const params = {
            rankingEventId: eventID,
            isShowPopup: false
        }
        const options = {
            method: 'PUT',
            url: url,
            data: params,
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            json: true,
        }
        return request(options)
            .then((response) => {
                if (response.data.code === 0) {
                    dispatch(resultSuccess(UPDATE_RANKING_EVENT_ANNOUNCED, eventID))
                    callback()
                } else {
                    throw 'システムエラー'
                }
            })
            .catch((error) => {
                throw error
            })
    }
}

export const getHistoryEvent = (page) => {
    return (dispatch) => {
        const token = localStorage.getItem('token')
        const url = glasConfig.url_base + glasConfig.get_all_event_ranking_history

        const options = {
            method: 'GET',
            url: url,
            params: {
                page: page,
                size: 20
            },
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            json: true,
        }
        return request(options)
            .then((response) => {
                if (response.data.code === 0) {
                    const data = response.data.data
                    dispatch(resultSuccess(GET_ALL_HISTORY_RANKING_EVENT, data))
                } else {
                    throw 'システムエラー'
                }
            })
            .catch((error) => {
                throw error
            })
    }
}
export const getEventSchedule = (time) => {
    return (dispatch) => {
        dispatch(resultSuccess(GET_EVENT_SCHEDULE, { data: [], currentMonth: moment(time, 'YYYYMM').format('YYYYMM') }))
        const token = localStorage.getItem('token')
        const url = glasConfig.url_base + glasConfig.get_event_calendar

        const options = {
            method: 'GET',
            url: url,
            params: {
                fromTime: moment(time, 'YYYYMM').startOf('month').format('YYYY-MM-DD HH:mm'),
                toTime: moment(time, 'YYYYMM').endOf('month').format('YYYY-MM-DD HH:mm')
            },
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            json: true,
        }
        return request(options)
            .then((response) => {
                if (response.data.code === 0) {
                    const data = response.data.data
                    dispatch(resultSuccess(GET_EVENT_SCHEDULE, { data: data, currentMonth: moment(time, 'YYYYMM').format('YYYYMM') }))
                } else {
                    throw 'システムエラー'
                }
            })
            .catch((error) => {
                throw error
            })
    }
}
