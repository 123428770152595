import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import RaisedButton from 'material-ui/RaisedButton'
import * as Fetch from '../util/Fetch'
import * as AuthActions from '../actions/AuthActions'

class Contract extends Component {
	constructor(props) {
		super(props)
		this.state = {
			consent: false,
		}
	}
	handlerClick(res) {
		const { dispatch } = this.props
		this.setState({ consent: res })
		if (res) {
			localStorage.setItem('isAcceptTermOfUser', 'true')
			dispatch(AuthActions.acceptTermOfUse())
			return;
		}
		return window.location.pathname = '/login'
	}


	render() {
		if (this.state.consent) {
			global.showDialogFirstLogin = true
			return <Redirect to={{ pathname: '/home' }} />
		}
		return (
			<div className="content">
				<h1 className="content__title">Stella 利用規約</h1>
				<p className="content__title title_color">
					【重要】 お仕事開始前に、下記の利用規約をお読みください。
				</p>
				<div className="primary">
					<div className="primary__inner">
						<dl className="definition">
							<dt className="definition__title write_down">
								第1項　当事者
							</dt>
							<dd className="definition__data margin_bottom">
								本規約は､占い師・カウンセラー(以下｢甲｣)と、Stella(以下｢本サービス｣)との間で交わされるものである｡
							</dd>
							<dt className="definition__title write_down">
								第2項　提供サービス
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本サービスは、甲に対し、下記のサービスを提供する。
								</p>
								<p>
									本サービスは、甲が規約に基づいて登録者にメッセージ、または音声通話等を確認するのに必要な、ウェブシステムを提供する。
								</p>
								<p>
									本サービスは、本サービスのサーバーシステムに対する包括的な保守・管理を提供する。
								</p>
								<p>
									本サービスは、利用者および登録者の安全と福利を奨励するために必要な規則を定めこれを施行する。
								</p>
							</dd>
							<dt className="definition__title write_down">
								第3項　本サービスの規約および規定
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本サービスの規約および規定に準拠している限り､甲は自由裁量で自己表現することができる｡
								</p>
								<p>
									ただし、本サービスより個別に指示があった際はその指示に準拠するものとする。
								</p>
								<p>
									本サービス上で､甲は個人情報を登録者に提供しないこと｡
								</p>
								<p>
									登録者に対して､個人情報を外部に漏洩しないこと。
								</p>
								<p>
									本サービスを利用できるユーザーは､18歳以上に限定するものとする｡
								</p>
								<p>
									利用前、また時宜によっては精算前に､年齢を証明するために年齢を示す正式書類のコピーの提出を必ず行わなければならない｡
								</p>
								<p>
									本人以外の出演、および第3者に出演をさせないこと。
								</p>
								<p>
									本人以外のプロフィール画像の利用を行わないこと。
								</p>
								<p>
									甲のコンテンツは､著作権法または登録商標法に準拠し､いかなる個人または企業の知的所有権も犯してはならない｡
								</p>
								<p>
									甲のコンテンツは､いかなる個人または企業の名誉を毀損または､誹謗､中傷してはならない｡
								</p>
								<p>
									甲はそのコンテンツに侮辱､嫌がらせ､虐待といった内容を含んではならない｡
								</p>
								<p>
									甲はそのコンテンツに政治的･宗教的内容を含んではならない｡
								</p>
								<p>
									甲のコンテンツは賭博やねずみ講などの違法行為を促進してはならない｡
								</p>
								<p>
									甲はそのコンテンツにﾊﾟｽﾜｰﾄﾞやｼﾘｱﾙ番号などの機密情報を含んではならない｡
								</p>
								<p>
									甲はそのコンテンツで他人を不快な思いをさせる方法（霊感商法など）を行ってはならない。
								</p>
								<p>
									甲はそのコンテンツに犯罪性の高いもしくは犯罪の恐れのある内容を含んではならない｡
								</p>
								<p>
									甲のコンテンツは公序良俗を犯してはならない｡
								</p>
								<p>
									甲のコンテンツは､本サービスの提供を妨害するあるいはその可能性のある行為､または第三者の本サービスの利用に支障を与える方法において本サービスを利用する行為をしてはならない。
								</p>
								<p>
									甲は登録者に対して、他サイトや他アプリへの誘導・宣伝を行ってはならない。
								</p>
								<p>
									甲は登録者に対して、物品の販売を行ってはならない。
								</p>
								<p>
									甲は本サービスのユーザーアカウントを自ら作成し、または他人にユーザーアカウントを作成させ、甲の占い師アカウントへポイントを加算する行為をしてはならない。また、甲は本サービスが不正と見做すシステムの悪用、及び不正にポイントを獲得する行為をしてはならない。
								</p>
								<p>
									｢本サービスの規約および規定｣に反した場合または､本サービスが不適合者とみなした場合､甲のアカウントを停止、削除、また収入没収の上退会とする。
								</p>
							</dd>
							<dt className="definition__title write_down">
								第4項　IDおよびﾊﾟｽﾜｰﾄﾞ管理
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									甲は、会員登録手続後に本サービスが甲に付与する、IDおよびパスワードの管理責任を負うものとする。
								</p>
								<p>
									IDおよびパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任は甲自身が負うものとし、本サービスは一切責任を負わない。
								</p>
								<p>
									甲は、IDおよびパスワードの盗難があった場合、IDおよびパスワードの失念があった場合、またはIDおよびパスワードが第三者に使用されていることが判明した場合には、直ちに本サービスにその旨連絡するとともに、指示がある場合には、これに従うものとする。
								</p>
							</dd>
							<dt className="definition__title write_down">
								第5項　登録者と利用者間の紛争
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									登録者のコンテンツ（映像、口頭、書面を含む）に関する問題は、利用者と登録者で解決を図るものとし、それによって生じた損害・問題について、本サービスは一切の責任を負わない。
								</p>
							</dd>
							<dt className="definition__title write_down">
								第6項　サービスの変更
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本サービスは、事前に予告なく、サービス内容の変更、新サービスの追加、サービスの一部または全部を、一時停止または終了することができる。
								</p>
								<p>
									こうした変更により登録者に不利益または損害が発生した場合でも、本サービスでは一切責任を負わない。
								</p>
								<p>
									予測不可能なサーバ、回線の問題、災害その他不意の事態がおきた場合、本サービスは事前通知なく、ウェブサイトをシャットダウンすることができる。
								</p>
								<p>
									本サービスはまた、サーバの保守管理を行う際にウェブサイトをシャットダウンする。
								</p>
								<p>
									本サービスの変更、中断、中止、またはデータの滅失、毀損、漏洩、不正使用に関連して発生した直接もしくは間接的な損害について、本サービスは一切の責任を負わない。
								</p>
							</dd>
							<dt className="definition__title write_down">
								第7項　規則の施行
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本サービスは甲が提供する会話内容を監視する義務を負わない｡{' '}
								</p>
								<p>
									しかし､本規約に対する違反行為が発見された場合､｢本サービスの規約および規定｣におけるその他事項を施行するため､本サービスの自由裁量で必要な措置を講じる権利を留保する｡
								</p>
								<p>
									禁止事項への違反行為があった場合､本サービスは､当該コンテンツの削除や､掲載場所の移動､違反行為を行った利用者の利用資格の停止または取り消し､本サービスの一部または全部のサービス提供を予告なく停止することができる｡
								</p>
							</dd>
							<dt className="definition__title write_down">
								第8項　サービスの中断
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									予測不可能なｻｰﾊﾞｰ､回線の問題､災害その他不意の事態がおきた場合､本サービスは事前通知なくｱﾌﾟﾘｹｰｼｮﾝを停止することができる｡
								</p>
								<p>
									本サービスはまた､サービスの保守管理を行う際にｱﾌﾟﾘｹｰｼｮﾝを停止する｡
								</p>
								<p>
									本サービスの変更､中断､中止､またはﾃﾞｰﾀの滅失､毀損､漏洩､不正使用に関連して発生した直接もしくは間接的な損害について､
									本サービスは一切の責任を負わない｡
								</p>
							</dd>
							<dt className="definition__title write_down">
								第9項　甲と利用者間の紛争
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									甲のコンテンツ(映像､口頭､書面を含む)に関する問題は､利用者と甲で解決を図るものとし､それによって生じた損害･問題について本サービスは一切の責任を負わない｡
								</p>
							</dd>
							<dt className="definition__title write_down">
								第10項　報酬の算定及び支払い
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本サービスは甲に代わって利用者より当該利用料金を代行して回収し､
									本サービスが収受すべき金額を控除した残額を甲に支払う｡
								</p>
								<p>
									本サービスは甲に代わって利用者に対し甲の有料サービス利用料を請求する｡
								</p>
								<p>
									報酬支払いは、甲からの請求を受けて、本サービスが行う履行義務を負うものとする。
								</p>
								<p>報酬支払いは、消費税を含めた内税として支払われる。</p>
								{Fetch.checkChangeFeeDate() ? (
									<p>
										甲の報酬は､甲の指定口座に直接入金される｡振込み手数料は､一律550円とし甲の負担とする｡
									</p>
								) : (
									<p>
										甲の報酬は､甲の指定口座に直接入金される｡振込み手数料は､一律540円とし甲の負担とする｡
									</p>
								)}
								<p>
									甲の銀行口座情報に不備があり、本サービスが報酬の振込をできなかった場合、事務手数料として1,500円分のポイントを差し引いて甲の占い師アカウントにポイントを返却する。
								</p>
								<p>
									甲が第3項に反する行為を行い本サービスが不利益を被った場合、本サービスは甲に対して所持ポイントの減算、または支払い済み報酬の返金を要求することができる。
								</p>
								<p>
									甲の報酬が5,550円に満たない場合､合計額が5,550円に到達するまで支払いは次回の支払日まで持ち越される｡
								</p>
								<p>
									甲が90日間にわたってコンテンツに出演者としてログインしない状況があった場合、その時点で保持しているポイントは抹消される。
								</p>
								<p>
									なお、その場合においてもIDは存続するものとする。
								</p>
								<p>
									本サービスが利用料金を回収できない場合(不正カード利用やその他犯罪行等その他)において、当該のチャットで発生した報酬は原則登録者に対しても支払わないものとする。
								</p>
								<p>
									甲が第3項に反する行為を行い本サービスが不利益を被った場合、本サービスは甲に対して所持ポイントの減算あるいは支払いの返金を要求することができる。
								</p>
							</dd>
							<dt className="definition__title write_down">
								第11項　非雇用関係
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									甲は､本サービスの社員､アルバイトあるいは請負契約者ではなく委託契約者となる｡
								</p>
								<p>
									よって本契約書に準拠して発生した報酬に対する税金の支払いについては､甲が責任を負うものとする｡
								</p>
							</dd>
							<dt className="definition__title write_down">
								第12項　契約書の変更
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本サービスは､甲に事前に通知することなくこの規約を変更する権利を有する｡
								</p>
								<p>
									甲はこれらの変更や改定内容確認その他を目的に甲用の管理ﾍﾟｰｼﾞを定期的に閲覧することに同意する｡
								</p>
							</dd>
							<dt className="definition__title write_down">
								第13項　準拠法および裁判管轄
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本契約は､日本国の法律に準拠するものとします｡
								</p>
								<p>
									本契約に関し､訴訟の必要性が生じた場合は､大阪地方裁判所を専属管轄裁判所とする｡
								</p>
							</dd>
							<dt className="definition__title write_down">
								第14項　適用期間
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本規約の適用期間は月極とし契約が終了しない限り自動的に更新される｡
								</p>
								<p>
									いずれの当事者も理由の如何に関わらず､電子ﾒｰﾙ､FAX､手紙その他書面による契約破棄通知をもって本契約書を即時破棄することができる｡
								</p>
							</dd>
							<dt className="definition__title write_down">
								第15項　完全合意
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									本契約書(｢の規約および規定｣を含む)は､甲と本サービスの間で完全合意した内容であり甲と本サービスの間で以前に交わされていた本契約書に関係する全ての同意事項に優先する｡
								</p>
							</dd>
							<dt className="definition__title write_down">
								第16項　著作権
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									登録者は、いかなる方法においても、当サイトを通じて提供されるいかなる情報も、著作権法で定める登録者個人の私的使用の範囲外で使用することはできない。
								</p>
								<p>
									本条の規定に違反して問題が発生した場合、登録者は、自己の費用と責任においてかかる問題を解決するとともに、当社に何等の迷惑または損害を与えないものとする。
								</p>
								<p>
									登録者が当サイトを通して提供するサービス(音声・画像・テキスト文書)において、著作権または肖像権、使用権は当サイトを運営する株式会社ステラに全て帰属することに合意する。
								</p>
								<p>
									株式会社ステラに帰属する提供サービス(音声・画像・テキスト文書)は株式会社ステラが必要に応じて活用することに合意する。
								</p>
							</dd>
							<dt className="definition__title write_down title_color title_decoration">
								特に重要な禁止事項
							</dt>
							<dd className="definition__data margin_bottom">
								<p>
									・ 名誉毀損、誹謗､中傷、侮辱、嫌がらせ、政治的･宗教的内容、霊感商法、物品販売、犯罪性の高いもしくは犯罪の恐れのある内容、その他他人を不快にさせる内容を含んではいけない 。
								</p>
								<p>
									・ 外部サイト・サービスへの誘導や宣伝をしてはいけない
								</p>
								<p>
									・ ユーザーアカウントを作成し自身の占い師アカウントにポイント加算するなど、不正にポイントを獲得する行為をしてはいけない
								</p>
							</dd>
						</dl>
					</div>
				</div>
				<div>
					<RaisedButton
						label="同意しない"
						onClick={() => this.handlerClick(false)}
						style={styleRaisedButton}
					/>
					<RaisedButton
						label="同意する"
						primary={true}
						onClick={() => this.handlerClick(true)}
						style={styleRaisedButton}
					/>
				</div>
			</div>

		)
	}
}

const styleRaisedButton = {
	margin: 10,
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps)(Contract)
