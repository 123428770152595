import jwtdecode from 'jwt-decode'
import { isTablet } from 'react-device-detect'
import { isIpad } from '../helper/helpFunction'
import { TYPE_LOCALSTORAGE } from '../constants/Config'

export function tellerId() {
	const token = localStorage.getItem('token')
	if (!token) {
		return ''
	}
	const decoded = jwtdecode(token)
	return decoded.userId
}

export function checkChangeFeeDate() {
	const currentTime = new Date().getTime()
	const changeFeeDate = new Date('2019/09/30').getTime()

	return currentTime > changeFeeDate
}

export function applicationId() {
	const token = localStorage.getItem('token')
	if (!token) {
		return ''
	}
	const decoded = jwtdecode(token)
	return decoded.usingApplication
}

export function chkExpireToken() {
	const token = localStorage.getItem('token')
	if (!token) {
		return false
	}
	const expiredRemainTime = localStorage.getItem('expiredRemainTime')
	if (!expiredRemainTime) {
		return false
	}
	const date = new Date()
	if (expiredRemainTime < date.getTime()) {
		return false
	}
	return true
}

export function fetchUserAgent() {
	const userAgent = navigator.userAgent.toLowerCase()
	if (userAgent.indexOf('iphone') != -1) return 1

	if (userAgent.indexOf('android') != -1) return 2

	return 3
}

export function getDeviceName() {
	const userAgent = navigator.userAgent.toLowerCase()
	if (userAgent.indexOf('iphone') != -1 || isIpad() || isTablet) return 'Mac'

	if (userAgent.indexOf('android') != -1) return 'Android'

	return 'Web'
}

/**
 * 身分証審査OKの判定
 * @param {string} status MyAccountやMyProfileで取得できるverifyAge
 * @return {boolean} true:身分証OK
 */
export function isAgeVerify(status) {
	if (status === undefined) {
		return status = false
	}
	return status === '1' || status.toLowerCase() === 'verified'
}

export function formatDate(date, format) {
	if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS'
	format = format.replace(/YYYY/g, date.getFullYear())
	format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
	format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2))
	format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2))
	format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
	format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
	if (format.match(/S/g)) {
		var milliSeconds = ('00' + date.getMilliseconds()).slice(-3)
		var length = format.match(/S/g).length
		for (var i = 0; i < length; i++)
			format = format.replace(/S/, milliSeconds.substring(i, i + 1))
	}
	return format
}

/**
 * number_format
 * @param {int} date
 */
export function formatNumber(date) {
	return String(date).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

export function setLocalStorage() {
	const statusAnswer = localStorage.getItem('statusAnswer')
	const categoryAnswer = localStorage.getItem('categoryAnswer')
	const sortByAnswer = localStorage.getItem('sortByAnswer')
	const showPopUpBestAnswer = localStorage.getItem('showPopUpBestAnswer')
	const notificationsLikeAnswer = localStorage.getItem(
		'notificationsLikeAnswer'
	)
	const tellerId = localStorage.getItem('tellerId')
	const flagMessageOffline = localStorage.getItem('flagMessageOffline')
	const flagConfirmSendTemplate = localStorage.getItem(
		'flagConfirmSendTemplate'
	)
	const orderByRequestOffline = localStorage.getItem('orderByRequestOffline')
	const sortByRequestOffline = localStorage.getItem('sortByRequestOffline')
	const valueRequestOffline = localStorage.getItem('valueRequestOffline')
	const dayShowDialogNewbie = localStorage.getItem(TYPE_LOCALSTORAGE.SHOW_DIALOG_NEWBIE)
	const SHOW_DIALOG_VALIDATE_TELLER = localStorage.getItem(TYPE_LOCALSTORAGE.SHOW_DIALOG_VALIDATE_TELLER)
	localStorage.clear()
	localStorage.setItem('statusAnswer', statusAnswer)
	localStorage.setItem('categoryAnswer', categoryAnswer)
	localStorage.setItem('sortByAnswer', sortByAnswer)
	localStorage.setItem('showPopUpBestAnswer', showPopUpBestAnswer)
	localStorage.setItem('notificationsLikeAnswer', notificationsLikeAnswer)
	localStorage.setItem('tellerId', tellerId)
	localStorage.setItem('flagMessageOffline', flagMessageOffline)
	localStorage.setItem('flagConfirmSendTemplate', flagConfirmSendTemplate)
	localStorage.setItem('orderByRequestOffline', orderByRequestOffline)
	localStorage.setItem('sortByRequestOffline', sortByRequestOffline)
	localStorage.setItem('valueRequestOffline', valueRequestOffline)
	localStorage.setItem(TYPE_LOCALSTORAGE.SHOW_DIALOG_NEWBIE, dayShowDialogNewbie)
	localStorage.setItem(TYPE_LOCALSTORAGE.SHOW_DIALOG_VALIDATE_TELLER, SHOW_DIALOG_VALIDATE_TELLER)
}

export function removeLocalStorage() {
	// remove localStorage push notification token
	localStorage.removeItem(TYPE_LOCALSTORAGE.PUSH_NOTIFICATION_TOKEN)
}
