import Checkbox from 'material-ui/Checkbox';
import React, { useEffect, useMemo, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { connect } from 'react-redux';

const CHECKED_YES = 1
const CHECKED_NO = 0

const FormValidateTeller = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const listTime = useMemo(() => {
    const data = props.Contact && props.Contact.listTime.map((i) => {
      return {
        label: i.dateString,
        value: i.dateString
      }
    })
    return data

  }, [props.Contact.listTime])

  const [data, setData] = useState({
    content: '',
    checked: CHECKED_YES
  })
  const onChange = (name, value) => {
    setData({
      ...data,
      [name]: value
    })
  }

  const handleCheckboxChange = (label) => {
    const valueDefault = ["受講できない", "上記以外の日程を希望"]
    if (valueDefault.includes(label)) {
      setSelectedValues((prev) =>
        prev.includes(label)
          ? prev.filter((v) => v !== label)
          : [label])
      return
    }
    setSelectedValues((prev) =>
      prev.includes(label)
        ? prev.filter((v) => v !== label)
        : [...prev.filter((v) => !valueDefault.includes(v)), label]
    );
  }
  const onAccept = () => {
    const params = { ...data, dateString: selectedValues }
    props.onChangeStepCategory(1, { ...params })
  }
  return (
    <div style={{ padding: 24 }}>
      <div>
        <p style={{
          fontSize: 15,
          color: '#000000de'
        }}>Stellaへ登録したての方に向けた説明会への参加予約はこちらからお願いいたします。 </p>
        <p style={{ color: '#ec407a', fontSize: 15 }}>※可能な限り、必ずご参加いただきますようお願いいたします。</p>

      </div>
      <p style={{
        fontSize: 15,
        color: '#000000de',
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 14
      }}>参加希望日 </p>
      {listTime.map((option) => (
        <div key={option.value}>
          <Checkbox
            label={option.label}
            onCheck={() => handleCheckboxChange(option.label)}
            checked={selectedValues.includes(option.label)}
            style={{
              color: '#c7bfbf', width: '27%'
            }}
            labelStyle={{ fontWeight: 'normal' }}
          />
        </div>
      ))}
      <textarea
        className="inputCus inputContact"
        onChange={(e) => onChange('content', e.target.value)}
        placeholder="上記以外の日程をご希望の方は、平日○○〜○○の間で希望日時を3つほどご記入ください。どうしても受講ができない方はその旨をご記入ください。その他、質問事項などもこちらにご記入ください。"
        value={data.content}
        style={{
          marginLeft: 0,
          marginTop: 20
        }}
      />
      <p className="titleTextBox" style={{ marginLeft: 0, marginTop: 24 }}>返信の有無</p>
      <div
        className={
          isAndroid
            ? 'checkBoxContact checkBoxContactAndroid'
            : 'checkBoxContact checkBoxContactIOS'
        }
        style={{
          marginLeft: 0,
        }}
      >
        <Checkbox
          label="必要"
          onCheck={() => onChange('checked', CHECKED_YES)}
          checked={data.checked === CHECKED_YES}
          style={{ color: '#c7bfbf' }}
          labelStyle={{ fontWeight: 'normal' }}
        />
        <Checkbox
          label="不要"
          onCheck={() => onChange('checked', CHECKED_NO)}
          checked={data.checked === CHECKED_NO}
          style={{ color: '#c7bfbf' }}
          labelStyle={{ fontWeight: 'normal' }}
        />
      </div>
      <button
        style={{
          marginLeft: 0,
        }}
        onClick={onAccept}
        className="submitContact"
        disabled={!selectedValues.length}
      >
        内容確認
      </button>
    </div>
  )
}
const mapStateToProps = (store) => {
  return {
    Contact: store.Contact
  }
}

export default connect(mapStateToProps)(FormValidateTeller)