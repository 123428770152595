import React, { useState } from 'react'
import Dropdown from 'react-dropdown'
import { isAndroid, isMobile } from 'react-device-detect'
import Checkbox from 'material-ui/Checkbox'
import Snackbar from 'material-ui/Snackbar'

const CHECKED_YES = 1
const CHECKED_NO = 0
const EnvBug = [
	{ value: 0, label: '選択してください' },
	{ value: 1, label: 'iOSアプリ版' },
	{ value: 2, label: 'Andoridアプリ版' },
	{ value: 3, label: 'PC版（ブラウザ：Google Chrome' },
	{ value: 4, label: 'PC版（ブラウザ：safari' },
	{ value: 5, label: 'PC版（ブラウザ：その他' },
	{ value: 6, label: 'スマホブラウザ版（iOS' },
	{ value: 7, label: 'スマホブラウザ版（Android' },
]
const OtherEnvBug = [
	{ value: 0, label: '選択してください' },
	{ value: 1, label: 'iOSアプリ版' },
	{ value: 2, label: 'Andoridアプリ版' },
	{ value: 3, label: 'PC版' },
	{ value: 4, label: 'スマホブラウザ版' },
]
const defaultOption1 = EnvBug[0]
const defaultOption2 = OtherEnvBug[0]


function FormOther(props) {
	const [image, setImage] = useState({
		error: false,
		message: '',
		image: null,
	})
	const [data, setData] = useState({
		content: '',
		file: null,
		checked: CHECKED_YES
	})
	const [err, setErr] = useState({
		content: false
	})
	const onChange = (name, value) => {
		setData({
			...data,
			[name]: value
		})
	}

	const getImageData = (file) => {
		let fileReader = new FileReader()
		fileReader.readAsDataURL(file)
		fileReader.onload = (ev) => {
			setImage({
				error: false,
				message: '',
				image: ev.target.result,
			})
		}
	}
	const selectFile = (event) => {
		let fileType = event.target.files[0].type
		if (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/gif') {
			getImageData(event.target.files[0])
			setData({ ...data, file: event.target.files[0] })
		} else {
			setImage({ error: true })
		}
	}
	const onAccept = () => {
		let content = false
		if (data.content.replace(/\s/g, '').length === 0) {
			content = true
		} else {
			content = false
		}

		setErr({ content })

		if (!content) {
			props.onChangeStepCategory(1, { ...data, image: image.image })
		}
	}
	return (
		<div>
			<p className="titleTextBox">お問い合わせ内容 </p>
			<textarea
				className="inputCus inputContact"
				onChange={(e) => onChange('content', e.target.value)}
				placeholder="具体的にお問い合わせ内容をご記入ください。 スクリーンショットを添付いただきますと対応がスムーズになります。"
				value={data.content}
			/>
			{
				err.content &&
				<p className="errMsgContact">
					お問い合わせ内容を入力してください
				</p>
			}
			{/* <p className="titleTextBox">不具合時のスクリーンショット(任意) </p> */}
			<div className="fileContact">
				<div className="btn-wrap btnFile">
					<label className="btn-raised color_white">
						<p className="btn-raised__text">
							ファイルを選択
						</p>
						<input
							type="file"
							className="btn-raised__file inputChooseFile"
							accept="image/*"
							onChange={(e) => selectFile(e)}
						/>
					</label>
				</div>
				{
					isMobile ?
						<>
							{image.image && (
								<img
									src={image.image}
									className="imageContact"
								/>
							)}
							<p className="txtFileContact">
								{image.image
									? '写真1枚'
									: 'ファイル未選択'}
							</p>
						</>
						:
						<>
							<p className="txtFileContact">
								{image.image
									? '写真1枚'
									: 'ファイル未選択'}
							</p>
							{image.image && (
								<img
									src={image.image}
									className="imageContact"
								/>
							)}
						</>
				}
			</div>

			<p className="titleTextBox" style={{ marginTop: 44 }}>返信の有無</p>
			<div
				className={
					isAndroid
						? 'checkBoxContact checkBoxContactAndroid'
						: 'checkBoxContact checkBoxContactIOS'
				}
			>
				<Checkbox
					label="必要"
					onCheck={() => onChange('checked', CHECKED_YES)}
					checked={data.checked === CHECKED_YES}
					style={{ color: '#c7bfbf' }}
					// iconStyle={{fill:'rgba(17, 173, 157, 1)' }}
					labelStyle={{ fontWeight: 'normal' }}
				/>
				<Checkbox
					label="不要"
					onCheck={() => onChange('checked', CHECKED_NO)}
					checked={data.checked === CHECKED_NO}
					style={{ color: '#c7bfbf' }}
					// iconStyle={{fill:'rgba(17, 173, 157, 1)' }}
					labelStyle={{ fontWeight: 'normal' }}
				/>
			</div>
			<button
				onClick={onAccept}
				className="submitContact"

			>
				内容確認
			</button>
			<Snackbar
				open={image.error}
				message={'メッセージを送信できません。このユーザーが存在していません。'}
				autoHideDuration={2500}
				onRequestClose={e => setImage({ ...image, error: false })}
			/>
		</div>
	)
}

export default FormOther