import Dialog from '@mui/material/Dialog'
import React, { useState } from 'react';
import './styles/DialogValidateWaiting.scss'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Fetch from '../../util/Fetch'

const DialogValidateWaiting = (props) => {
  const { MyProfile, closeFunc } = props
  const [show] = useState(props.show)
  const closeDialog = () => {
    closeFunc()
  }
  const handleAgeAuth = () => {
    closeDialog()
    props.history.push(`/home/setting/ageauth`)
    return null
  }
  const handeNumberPhone = () => {
    closeDialog()
    props.history.push(`/home/setting`)
    return null
  }
  const handleSubmitForm = () => {
    closeDialog()
    props.history.push(`/home/contact`, { type: 5 })
    return null
  }
  return (
    <div>
      <Dialog
        open={show}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div style={{ maxWidth: '328px' }} id='dialog_validate_waiting'>
          <img src={!MyProfile.data.firstTimeAssignedAgent ? '/img/banner_waiting_val.png' : '/img/banner_waiting_val_2.png'} style={{ marginBottom: 0 }} />
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            className='icon_close'
          >
            <CloseIcon />
          </IconButton>
          <div className='root'>
            <div style={{ color: '#ec407a', fontSize: '14px', fontWeight: 'bold' }}>① 本人確認</div>
            <div style={{ color: '#212020', fontSize: '14px' }}>身分証のご提出をお願いします。</div>
            <button className='dialog-button' onClick={handleAgeAuth} disabled={Fetch.isAgeVerify(MyProfile.data.verifyAge)}>
              {Fetch.isAgeVerify(MyProfile.data.verifyAge) ? '提出済み' : '①本人確認はこちら'}
            </button>
            <div style={{ color: '#ec407a', fontSize: '14px', fontWeight: 'bold' }}>② プロフィールの設定</div>
            <div style={{ color: '#212020', fontSize: '14px' }}>プロフィールの作成とプロフ画像の申請を行いましょう。</div>
            <button className='dialog-button' onClick={handeNumberPhone} disabled={MyProfile.data.isAllowChangeStatus}>
              {MyProfile.data.isAllowChangeStatus ? '画像申請済み' : '②プロフィール設定はこちら'}
            </button>
            {!MyProfile.data.firstTimeAssignedAgent &&
              <>
                <div style={{ color: '#ec407a', fontSize: '14px', fontWeight: 'bold' }}>③ 説明会の受講</div>
                <div style={{ color: '#212020', fontSize: '14px' }}>お仕事開始前に説明会の受講をお願いしております。都合の良い日時をお選びください。</div>
                <button className='dialog-button' onClick={handleSubmitForm} disabled={MyProfile.data.isAttendedMeeting}>
                  {MyProfile.data.isAttendedMeeting ? '予約済み' : '③説明会の予約はこちら'}
                </button>
              </>}
          </div>
        </div>
      </Dialog >
    </div >
  )
}
const mapStateToProps = (state) => {
  return {
    MyProfile: state.MyProfile
  }
}
export default withRouter(connect(mapStateToProps)(DialogValidateWaiting))