/**
 * Created by ToanNC on 9/11/2017.
 */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import * as Fetch from '../util/Fetch'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const expire = Fetch.chkExpireToken()
  const isAcceptTermOfUser = localStorage.getItem('isAcceptTermOfUser')
  return (
    <Route
      {...rest}
      render={(props) =>
        isAcceptTermOfUser === 'false' ?
          (
            <Redirect
              to={{
                pathname: '/contract',
              }}
            />
          )
          :
          (
            expire ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            )
          )
      }
    />
  )
}
