import { colors, fonts } from '../util/StellaConstant'
import { isPWA } from '../helper/helpFunction'
import moment from 'moment'

export const POINT_REPORT = {
	TARGET_POINT_OPTION: {
		0: 'SAME_AS_PREVIOUS_MONTH',
		1: 'INCREASE_10_PERCENT',
		2: 'INCREASE_20_PERCENT',
		3: 'OPTIONAL_NUMBER',
	},
	TARGET_POINT_OPTION_INDEX: {
		SAME_AS_PREVIOUS_MONTH: 0,
		INCREASE_10_PERCENT: 1,
		INCREASE_20_PERCENT: 2,
		OPTIONAL_NUMBER: 3,
	},
	LABEL_CHECKBOXES: {
		SAME_AS_PREVIOUS_MONTH: '前月の獲得ポイント',
		INCREASE_10_PERCENT: '前月の獲得ポイントから1.1倍',
		INCREASE_20_PERCENT: '前月の獲得ポイントから1.2倍'
	},
	TYPE_OF_CHECKBOXES: {
		SAME_AS_PREVIOUS_MONTH: 'SAME_AS_PREVIOUS_MONTH',
		INCREASE_10_PERCENT: 'INCREASE_10_PERCENT',
		INCREASE_20_PERCENT: 'INCREASE_20_PERCENT',
		OPTIONAL_NUMBER: 'OPTIONAL_NUMBER',
	},
	BUTTON_NAVIGATE: {
		PREVIOUS: 'PREV',
		NEXT: 'NEXT',
		TODAY: 'TODAY',
		DATE: 'DATE'
	},
	DATE_INDEX: {
		DATE_FNS: {
			SATURDAY: 7,
			SUNDAY: 1,
		},
		MOMENT_JS: {
			SATURDAY: 6,
			SUNDAY: 0,
		}
	},
	TABS: [
		{ id: 1, label: 'レポート', value: 'chart' },
		{ id: 2, label: 'カレンダー', value: 'calendar' },
		{ id: 3, label: 'イベント履歴', value: 'event' },
	],
	TYPES: {
		TARGET_POINT: 'TARGET_POINT',
		ACTUAL_POINT: 'ACTUAL_POINT',
		POINT_BONUS: 'POINT_BONUS',
		ACTUAL_POINT_ADDITION: 'ACTUAL_POINT_ADDITION',
		WORKING_DAY: 'WORKING_DAY',
		WORKING_DAY_ADDITION: 'WORKING_DAY_ADDITION',
		RATE_OF_PAID_MESSAGES: 'RATE_OF_PAID_MESSAGES',
		RATE_OF_RESPONSING_USER_QUESTIONS: 'RATE_OF_RESPONSING_USER_QUESTIONS',
		RATE_OF_RESPONSING_USER_QUESTIONS_ADDITION: 'RATE_OF_RESPONSING_USER_QUESTIONS_ADDITION',
	},
	TITLES: {
		TARGET_POINT: {
			label: '今月目標ポイント',
			color: colors.accent,
			backgroundColor: 'transparent',
			fontFamily: {
				mobile: fonts.HiraKakuProW3,
				laptop: fonts.HiraKakuProW3,
				laptopL: fonts.HiraKakuProW3,
			},
			fontSize: {
				mobile: '12px',
				laptop: '15px',
				laptopL: '15px',
			},
			isHaveBackground: false
		},
		ACTUAL_POINT: {
			label: '今月獲得ポイント',
			color: colors.pearlAqua,
			backgroundColor: 'transparent',
			fontFamily: {
				mobile: fonts.HiraKakuProW3,
				laptop: fonts.HiraKakuProW3,
				laptopL: fonts.HiraKakuProW3,
			},
			fontSize: {
				mobile: '12px',
				laptop: '15px',
				laptopL: '15px',
			},
			isHaveBackground: false
		},
		POINT_BONUS: {
			label: '今月のボーナスポイント',
			color: colors.pearlAqua,
			backgroundColor: colors.azureMist,
			fontFamily: {
				mobile: fonts.HiraKakuProW3,
				laptop: fonts.HiraKakuProW3,
				laptopL: fonts.HiraKakuProW3,
			},
			fontSize: {
				mobile: '12px',
				laptop: '14px',
				laptopL: '14px',
			},
			isHaveBackground: false
		},
		WORKING_DAY: {
			label: '今月の稼働日数',
			color: colors.pearlAqua,
			backgroundColor: colors.azureMist,
			fontFamily: {
				mobile: fonts.HiraKakuProW3,
				laptop: fonts.HiraKakuProW3,
				laptopL: fonts.HiraKakuProW3,
			},
			fontSize: {
				mobile: '12px',
				laptop: '14px',
				laptopL: '14px',
			},
			isHaveBackground: true
		},
		RATE_OF_PAID_MESSAGES: {
			label: '今月の有料メッセージ比率',
			color: colors.pearlAqua,
			backgroundColor: colors.azureMist,
			fontFamily: {
				mobile: fonts.HiraginoKakuGothicPro,
				laptop: fonts.HiraKakuProW3,
				laptopL: fonts.HiraKakuProW3,
			},
			fontSize: {
				mobile: '12px',
				laptop: '14px',
				laptopL: '14px',
			},
			isHaveBackground: true
		},
		RATE_OF_RESPONSING_USER_QUESTIONS: {
			label: '今月の教えて!先生回答数',
			color: colors.pearlAqua,
			backgroundColor: colors.azureMist,
			fontFamily: {
				mobile: fonts.HiraKakuProW3,
				laptop: fonts.HiraKakuProW3,
				laptopL: fonts.HiraKakuProW3,
			},
			fontSize: {
				mobile: '12px',
				laptop: '14px',
				laptopL: '14px'
			},
			isHaveBackground: true
		}
	},
	STATISTICS: {
		TARGET_POINT: {
			DATA: {
				defaultValue: '—',
				color: colors.accent,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraKakuProW6,
					laptopL: fonts.HiraKakuProW6,
				},
				fontSize: {
					mobile: '24px',
					laptop: '26px',
					laptopL: '30px',
				},
			},
			UNIT: {
				value: 'pts',
				color: colors.accent,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraKakuProW6,
					laptopL: fonts.HiraKakuProW6
				},
				fontSize: {
					mobile: '14px',
					laptop: '16px',
					laptopL: '20px'
				},
			}
		},
		ACTUAL_POINT: {
			DATA: {
				defaultValue: '—',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraKakuProW6,
					laptopL: fonts.HiraKakuProW6
				},
				fontSize: {
					mobile: '24px',
					laptop: '26px',
					laptopL: '30px'
				},
			},
			UNIT: {
				value: 'pts',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraKakuProW6,
					laptopL: fonts.HiraKakuProW6
				},
				fontSize: {
					mobile: '14px',
					laptop: '16px',
					laptopL: '20px'
				},
			}
		},
		POINT_BONUS: {
			DATA: {
				defaultValue: '—',
				color: 'rgba(33, 32, 32, 1)',
				fontFamily: {
					mobile: fonts.HiraginoKakuGothicPro,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '24px',
					laptop: '26px',
					laptopL: '30px'
				},
			},
			UNIT: {
				value: 'pts',
				color: 'rgba(33, 32, 32, 1)',
				fontFamily: {
					mobile: fonts.HiraginoKakuGothicPro,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '14px',
					laptop: '16px',
					laptopL: '20px'
				},
			}
		},
		ACTUAL_POINT_ADDITION: {
			DATA: {
				defaultValue: '—',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW3,
					laptop: fonts.HiraKakuProW3,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '13px',
					laptop: '14px',
					laptopL: '16px'
				},
			},
			UNIT: {
				value: 'pts',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW3,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '13px',
					laptop: '14px',
					laptopL: '16px'
				},
			}
		},
		WORKING_DAY: {
			DATA: {
				defaultValue: '—',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '24px',
					laptop: '28px',
					laptopL: '28px'
				},
			},
			UNIT: {
				value: '日',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '15px',
					laptop: '17px',
					laptopL: '17px'
				},
			}
		},
		WORKING_DAY_ADDITION: {
			DATA: {
				defaultValue: '—',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW3,
					laptop: fonts.HiraKakuProW3,
					laptopL: fonts.HiraKakuProW3
				},
				fontSize: {
					mobile: '14px',
					laptop: '16px',
					laptopL: '16px'
				},
			},
			UNIT: {
				value: '日',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW3,
					laptop: fonts.HiraKakuProW3,
					laptopL: fonts.HiraKakuProW3
				},
				fontSize: {
					mobile: '14px',
					laptop: '16px',
					laptopL: '16px'
				},
			}
		},
		RATE_OF_PAID_MESSAGES: {
			DATA: {
				defaultValue: '—',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '24px',
					laptop: '28px',
					laptopL: '28px'
				},
			},
			UNIT: {
				value: '%',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '15px',
					laptop: '17px',
					laptopL: '17px'
				},
			}
		},
		RATE_OF_RESPONSING_USER_QUESTIONS: {
			DATA: {
				defaultValue: '—',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '24px',
					laptop: '28px',
					laptopL: '28px'
				},
			},
			UNIT: {
				value: '件',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW6,
					laptop: fonts.HiraginoKakuGothicPro,
					laptopL: fonts.HiraginoKakuGothicPro
				},
				fontSize: {
					mobile: '15px',
					laptop: '17px',
					laptopL: '17px'
				},
			}
		},
		RATE_OF_RESPONSING_USER_QUESTIONS_ADDITION: {
			DATA: {
				defaultValue: '—',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW3,
					laptop: fonts.HiraKakuProW3,
					laptopL: fonts.HiraKakuProW3
				},
				fontSize: {
					mobile: '14px',
					laptop: '16px',
					laptopL: '16px'
				},
			},
			UNIT: {
				value: '件',
				color: colors.pearlAqua,
				fontFamily: {
					mobile: fonts.HiraKakuProW3,
					laptop: fonts.HiraKakuProW3,
					laptopL: fonts.HiraKakuProW3
				},
				fontSize: {
					mobile: '14px',
					laptop: '16px',
					laptopL: '16px'
				},
			}
		},
	},
	RATE_OF_PAID_MESSAGES_NOTICE: {
		LOWER_THAN_30: 'かなり無料メッセージが多いです。\n鑑定結果は必ず有料へ伝え、有料文字数を2倍ほど増やしてみましょう。',
		LOWER_THAN_45: '無料メッセージが多めです。\n有料メッセージを1.5倍ほどに増やしてみましょう！',
		LOWER_THAN_55: '良い調子ですが、まだ少し無料メッセージが多いです。\nもう少しだけ有料を増やすよう意識してみましょう！',
		LOWER_THAN_65: '良いです！\nもう少し有料を増やしてみて、65%を超えてみましょう！',
		LOWER_THAN_80: '素晴らしいです！\nこの調子でいきましょう！',
		GREATER_THAN_80: '有料メッセージが多めです。\n挨拶やお礼、クッション言葉などを無料メッセージで送るとリピーターを獲得しやすくなります。',
	},
	DATAKEY: {
		DAY: 'day',
		TARGET_POINT: 'targetPoint',
		ACTUAL_POINT: 'actualPoint',
	}
}

export const getRateOfPaidMessagesNotice = (rate = 0) => {
	let notice = ''
	if (rate <= 30) {
		notice = POINT_REPORT.RATE_OF_PAID_MESSAGES_NOTICE['LOWER_THAN_30']
	} else if (rate <= 45) {
		notice = POINT_REPORT.RATE_OF_PAID_MESSAGES_NOTICE['LOWER_THAN_45']
	} else if (rate <= 55) {
		notice = POINT_REPORT.RATE_OF_PAID_MESSAGES_NOTICE['LOWER_THAN_55']
	} else if (rate <= 65) {
		notice = POINT_REPORT.RATE_OF_PAID_MESSAGES_NOTICE['LOWER_THAN_65']
	} else if (rate <= 80) {
		notice = POINT_REPORT.RATE_OF_PAID_MESSAGES_NOTICE['LOWER_THAN_80']
	} else {
		notice = POINT_REPORT.RATE_OF_PAID_MESSAGES_NOTICE['GREATER_THAN_80']
	}
	return notice
}

export const getMaxYAxis = (dataMax) => {
	let max = 0
	if (dataMax % 5000 === 0) {
		max = dataMax
	} else {
		max = (Math.floor(dataMax / 5000) + 1) * 5000
	}
	return max
}

export const getDaysInMonth = () => moment().daysInMonth()
export const getCurrentDate = () => moment().date()

export const getTicks = () => {
	let result = []
	if (!isPWA()) {
		const numberOfDayInThisMonth = getDaysInMonth()

		for (let index = 1; index <= numberOfDayInThisMonth; index++) {
			if (index === 1 || index === numberOfDayInThisMonth || index % 5 === 0) {
				result = [...result, index]
			}
		}
	} else {
		const currentDate = getCurrentDate()
		if (currentDate > 7) {
			for (let index = currentDate - 7; index < currentDate; index++) {
				if (index > 0) {
					let temp = `${index}日`
					result = [...result, temp]
				}
			}
		} else {
			for (let index = 1; index <= 7; index++) {
				if (index > 0) {
					let temp = `${index}日`
					result = [...result, temp]
				}
			}
		}

	}
	return result
}

const getDay = miliseconds => moment(miliseconds).utcOffset(9).date()

export const generateDataForChart = (dataFromServer, targetPoint) => {
	let pointInMonth = []
	if (!isPWA()) {
		const numberOfDayInThisMonth = getDaysInMonth()
		const targetPointPerDay = targetPoint / numberOfDayInThisMonth

		for (let day = 1; day <= numberOfDayInThisMonth; day++) {
			const findDay = dataFromServer.find(dayItem => getDay(dayItem.time) === day)
			const pointPerDay = {
				day: day,
				targetPoint: +(targetPointPerDay * day).toFixed(2),
				actualPoint: findDay ? findDay.point.toFixed(2) : null
			}
			pointInMonth = [...pointInMonth, pointPerDay]
		}
	} else {
		const currentDate = getCurrentDate()
		const numberOfDayInThisMonth = getDaysInMonth()
		const targetPointPerDay = targetPoint / numberOfDayInThisMonth

		if (currentDate > 7) {
			for (let day = currentDate - 7; day < currentDate; day++) {
				const findDay = dataFromServer.find(dayItem => getDay(dayItem.time) === day)
				const pointPerDay = {
					day: `${day}日`,
					targetPoint: +(targetPointPerDay * day).toFixed(2),
					actualPoint: findDay ? findDay.point.toFixed(2) : null
				}
				pointInMonth = [...pointInMonth, pointPerDay]
			}
		} else {
			for (let day = 1; day <= 7; day++) {
				const findDay = dataFromServer.find(dayItem => getDay(dayItem.time) === day)
				const pointPerDay = {
					day: `${day}日`,
					targetPoint: +(targetPointPerDay * day).toFixed(2),
					actualPoint: findDay ? findDay.point.toFixed(2) : null
				}
				pointInMonth = [...pointInMonth, pointPerDay]
			}
		}

	}

	return pointInMonth
}


export const generateDataForCalendar = (dataFromServer, currentMonth) => {
	const numberOfDayInThisMonth = moment(currentMonth).daysInMonth()
	let listPointPerDay = []
	for (let day = 1; day <= numberOfDayInThisMonth; day++) {
		const findDay = dataFromServer.find(dayItem => getDay(dayItem.time) === day)
		const pointPerDay = {
			id: day,
			title: findDay ? findDay.point : 0,
			allDay: true,
			start: findDay ? new Date(moment(findDay.time).utcOffset(9).format('YYYY-MM-DD').replace(/-/g, "/")) : day * 8.64e+7,
			end: findDay ? new Date(moment(findDay.time).utcOffset(9).add(1, 'days').format('YYYY-MM-DD').replace(/-/g, "/")) : day * 8.64e+7 + 1,
		}
		listPointPerDay = [...listPointPerDay, pointPerDay]
	}
	return listPointPerDay
}

export const getCurrentMonth = () => moment().format('YYYYMM')

export const getPreviousMonth = (currentMonth) => moment(currentMonth, 'YYYYMM').subtract(1, 'month').format('YYYYMM')
export const getNextMonth = (currentMonth) => moment(currentMonth, 'YYYYMM').add(1, 'month').format('YYYYMM')
export const setLimitForTargetPoint = targetPoint => targetPoint < 10000 ? 10000 : targetPoint
export const isInt = number => Number(number) === number && number % 1 === 0