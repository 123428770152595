import React, { useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import ja from 'date-fns/locale/ja'
import MonthHeader from '../../PointReport/Frames/CustomCalendar/MonthHeader';
import DayInMonth from '../../PointReport/Frames/CustomCalendar/DayInMonth';
import { getDay, startOfWeek, parse, format } from 'date-fns'
import { EventScheduleStyled } from '../StyledEventSchedule/StyledCalendar';
import Toolbar from '../common/Toolbar';
import moment from 'moment';
import '../StyledEventSchedule/EventSchedule.scss'
import { connect } from 'react-redux';
import * as RankingEventActions from '../../../actions/RankingEventActions'
import { useEffect } from 'react';
import { getCurrentMonth } from '../../../constants/PointReportConstants';
import { COLOR_EVENT_SCHEDULE } from '../../../constants/Config';

const localizer = dateFnsLocalizer({
    format,
    parse,
    getDay,
    startOfWeek: (date, options) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
    locales: { 'ja': ja },
})

const formats = {
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'ccc', culture),
    dateFormat: (date, culture, localizer) => localizer.format(date, 'd', culture),
    monthHeaderFormat: (date, culture, localizer) => `${localizer.format(date, 'y', culture)}年${localizer.format(date, 'M', culture)}月`
}

const EventSchedule = (props) => {
    const { RankingEvent, dispatch } = props
    const [isHovered, setIsHovered] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [dataHover, setDataHover] = useState()

    useEffect(() => {
        const currentMonth = getCurrentMonth()
        dispatch(RankingEventActions.getEventSchedule(currentMonth))
    }, [])

    useEffect(() => {
        handleMouseLeave()
    }, [RankingEvent.lstEventSchedule])

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const EventWithPopup = ({ event }) => {
        const handleMouseEnter = (e) => {
            const rect = e.target.getBoundingClientRect();
            setPopupPosition({ top: rect.top - 10 - 230, left: rect.left > 1300 ? 1300 : rect.left - 200 });
            setIsHovered(true);
            setDataHover(event)
        };
        return (
            <>
                <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        position: "relative",
                        backgroundColor: COLOR_EVENT_SCHEDULE.find(i => i.value === event.color).label,
                        color: 'white',
                        padding: '2px 4px', marginInline: 3,
                        fontSize: 12,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}
                >
                    {event.title}
                </div>
            </>
        );
    };

    const components = {
        toolbar: Toolbar,
        month: {
            header: MonthHeader,
            dateHeader: DayInMonth,
        },
        event: EventWithPopup,
    }

    return (
        <EventScheduleStyled id='event-calendar'>
            <Calendar
                localizer={localizer}
                events={RankingEvent.lstEventSchedule}
                startAccessor="start"
                endAccessor="end"
                culture='ja'
                components={components}
                formats={formats}
                views={['month']}
                style={{
                    width: '100%',
                }}
                tooltipAccessor={''}
            />
            {isHovered && (
                <div
                    onMouseLeave={handleMouseLeave}
                    style={{
                        position: "fixed",
                        top: popupPosition.top,
                        left: popupPosition.left,
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        padding: "5px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                        zIndex: 1000,
                        maxWidth: '40%'

                    }}
                >
                    <strong style={{ whiteSpace: "break-spaces" }}>{dataHover.title}</strong>
                    <p>{moment(dataHover.start_time).format("MM/DD HH:mm")} ~ {dataHover.end_time.length < 11 ? moment(dataHover.end_time).format("MM/DD 23:59") : moment(dataHover.end_time).format("MM/DD HH:mm")}</p>
                </div>
            )}
        </EventScheduleStyled>
    )
}

const mapStateToProps = (state) => {
    return {
        RankingEvent: state.RankingEvent
    }
}
export default connect(mapStateToProps)(EventSchedule)