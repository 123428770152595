import React, { useRef, useState } from 'react'
import DialogContactConfirm from '../DialogContactConfirm'
import { connect } from 'react-redux'
import * as ContactActions from '../../actions/ContactActions'
import SparkMD5 from 'spark-md5'
import Loading from '../Loading'
import { isMobile } from 'react-device-detect'
import { LinkItUrl } from 'react-linkify-it'

function PreviewBug(props) {
	const refModal = useRef(null)
	const [loading, setLoading] = useState(false)
	const showDialog = () => {
		refModal.current.openDialog(() => onConfirm())
	}

	const getContentDevice = () => {
		if (props.data.envBug !== undefined) {
			return `${props.data.envBug.label} ${props.data.otherEnvBug.value !== 0 ? ', ' + props.data.otherEnvBug.label : ''}`
		} else {
			return ''
		}

	}

	const onConfirm = async () => {
		let params = {}
		let formData = new FormData()
		const { image, file, content, checked, category, dateString } = props.data
		const { dispatch, MyProfile } = props
		await setLoading(true)

		if (image) {
			formData.append('file', file)
			let fileReader = new FileReader()
			let spark = new SparkMD5.ArrayBuffer()
			fileReader.onload = async (event) => {
				let data = event.target.result
				spark.append(data)
				let hash = spark.end()
				formData.append('md5Sum', hash)
				params.fileData = formData
				params.body = content
				params.userId = MyProfile.data.fortuneTellerId
				params.userName = MyProfile.data.fortuneTellerName
				params.reply = checked
				params.threadId = null
				params.subject = category.label
				params.content = getContentDevice()
				await dispatch(ContactActions.postFileQuestion(params))
			}
			await fileReader.readAsArrayBuffer(file)

		} else {
			params.body = content
			params.userId = MyProfile.data.fortuneTellerId
			params.userName = MyProfile.data.fortuneTellerName
			params.reply = checked
			params.threadId = null
			params.subject = category.label
			params.content = getContentDevice()
			params.dateString = dateString && dateString.join(", ")
			await dispatch(ContactActions.postQuestion(params))
			if (dateString.length) {
				await dispatch(ContactActions.updateAttendedMeeting(MyProfile.data))
			}
		}
	}
	if (props.data.content === undefined) {
		return null
	}
	const regexToMatch = /@([\w_]+)/
	return (
		<div className="primary contactPrimary">
			<div className="primary-title hidden-sp">
				<h1 className="primary-title__main">
					お問い合わせ内容確認
				</h1>
			</div>
			<p className="titleTextField">お問い合わせ項目</p>
			<p className="titleValuePreview">{props.data.category.label}</p>

			{
				props.data.envBug !== undefined &&
				<>
					<p className="titleTextBox">ご利用環境</p>
					<p className="titleValuePreview">{getContentDevice()}</p>
				</>
			}

			<p className="titleTextField">{props.data.dateString ? '参加希望日時' : '不具合の詳細'}</p>
			{props.data.dateString && props.data.dateString.map((i) => {
				return <p className="titleValuePreview">{i}</p>
			})}
			<p className="titleValuePreview">
				<LinkItUrl
					component={(match, key) => <a href={match} key={key}>{match}</a>}
					regex={regexToMatch}>
					{props.data.content}
				</LinkItUrl>
			</p>
			{(props.data.image !== null && props.data.image !== undefined) &&
				<img style={{ width: 200, marginTop: isMobile ? 15 : 24, marginLeft: isMobile ? 15 : 24 }}
					src={props.data.image}
				/>

			}

			<p className="titleTextField">返信の有無</p>
			<p className="titleValuePreview">{props.data.checked ? '必要' : '不要'}</p>

			<hr style={{ marginLeft: 24 }} />

			<p className="titleValuePreview" style={{ marginBottom: 7 }}>上記にお間違いがないかご確認の上、{isMobile ? '\n' : ''}「送信」ボタンを押してください。</p>
			<p className="titleValuePreview" style={{ fontSize: 12, fontFamily: 'HiraKakuPro-W3', color: 'rgba(177, 177, 177, 1)' }}>返信の有無にて「不要」を選択している場合は、{isMobile ? '\n' : ''}事務局からの返信はございませんので、{'\n'}ご了承くださいませ。</p>

			<div className="boxBtnPreview">
				<button
					onClick={showDialog}
					className="submitContact"
				>
					送信
				</button>

				<button
					onClick={() => props.onChangeStepCategory(0)}
					className="submitContact"
					style={{
						color: 'rgba(0, 0, 0, 0.38)', backgroundColor: 'rgba(229, 229, 229, 1)'
					}}
				>
					戻る
				</button>
			</div>

			<DialogContactConfirm
				ref={refModal}
			/>
			<Loading display={loading} />
		</div >
	)
}
const mapStateToProps = (store) => {
	return {
		MyProfile: store.MyProfile,
		Contact: store.Contact,
	}
}
export default connect(mapStateToProps)(PreviewBug)