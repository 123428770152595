/**
 * 占い師のプロフィール情報を表示
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as MyProfileActions from '../actions/MyProfileActions'
import {
	glasConfig,
	fortuneTellerStatusToString,
	fortuneTellerStatus,
} from '../constants/Config'
import StatusControl from './StatusControl'
import { AvatarStatus } from '../constants/Config'
import $ from 'jquery'
import request from 'axios'
import { isPWA } from '../helper/helpFunction'
import { DialogAppealMessage } from './LeftMenu/DialogAppealMessge'

const ID_POST_REQUIRED = 894
class LeftProfile extends Component {
	constructor(props) {
		super(props)
		const { MyProfile } = this.props
		const mood = MyProfile.data.mood ? MyProfile.data.mood : ''
		this.state = {
			mood: mood,
			status: MyProfile.data.fortuneTellerStatus,
			mood_length: mood.length,
			showStatusbox: false,
			showTooltip: false,
			showUpdateButton: false,
			textAreaFocus: false,
			isExist: false,
			isShowPopupAppealMessage: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.MyProfile.data.fortuneTellerStatus !== this.state.status
		) {
			this.setState({
				status: nextProps.MyProfile.data.fortuneTellerStatus,
				showStatusbox: false,
			})
		}
	}

	handleChange(e) {
		// 一言メッセージ
		if (e.target.name === 'mood') {
			const value = e.target.value.replace(/\s/g, '')
			const length = value.length
			//規定の文字数以上は入力不可
			if (length > glasConfig.length_mood) {
				return
			}
			this.setState({
				mood: value,
				mood_length: length,
				showStatusbox: false,
			})
		}
	}

	saveMood(e) {
		//一言メッセージ更新
		const { dispatch, MyProfile } = this.props
		const data = MyProfile.data
		data['mood'] = this.state.mood
		dispatch(MyProfileActions.put('mood', data))
		this.setState({
			showUpdateButton: false,
		})
	}

	//待機状態のBOXを表示
	showToggle(e) {
		this.setState({
			showStatusbox: !this.state.showStatusbox,
		})
	}

	/**
	 * テキストエリアのマウスオーバーイベント
	 * 一言メッセージのツールチップを表示
	 */
	handleOpenToolTip() {
		if (this.state.textAreaFocus) {
			//テキストエリアにフォーカスが当たっている場合は表示しない
			return
		}
		this.setState({
			showTooltip: true,
		})
	}

	/**
	 * テキストエリアのマウスオーバーイベント
	 * 一言メッセージのツールチップを非表示
	 */
	handleCloseToolTip() {
		this.setState({
			showTooltip: false,
		})
	}
	handleBlur() {
		this.setState({
			textAreaFocus: false,
		})
	}
	handleFocus() {
		this.setState({
			textAreaFocus: true,
			showTooltip: false,
			showUpdateButton: true,
		})
	}

	goSetting = () => {
		const { closeNavLeft } = this.props
		$('.message_text').removeClass('hiddenClass')
		closeNavLeft()
	}

	popUpAppealMessage = () => {
		this.setState({
			isShowPopupAppealMessage: true,
		})
	}

	resetToggleAppealMessage = () => {
		this.setState({
			isShowPopupAppealMessage: false,
		})
	}

	render() {
		const { MyProfile } = this.props
		const blogLink = `/home/reward/RewardExplanation`

		if (!MyProfile.loaded) {
			return null
		}

		//得意占術（複数）
		let fortuneMethods = ''
		if (Array.isArray(MyProfile.data.fortuneMethods)) {
			fortuneMethods = MyProfile.data.fortuneMethods.map((e) => {
				return <span key={e.toString()}>{e}</span>
			})
		}

		//得意ジャンル（複数）
		let judgementGenres = ''
		if (Array.isArray(MyProfile.data.judgementGenres)) {
			judgementGenres = MyProfile.data.judgementGenres.map((e) => {
				return <span key={e.toString()}>{e}</span>
			})
		}

		//相談スタイル(複数)
		let styles = ''
		if (Array.isArray(MyProfile.data.styles)) {
			styles = MyProfile.data.styles.map((e) => {
				return <span key={e.toString()}>{e}</span>
			})
		}

		// 残り文字数
		const moodLength =
			glasConfig.length_mood > this.state.mood_length
				? glasConfig.length_mood - this.state.mood_length
				: ''

		// Enterキーで保存
		const moodkeyEvent = (e) => {
			if (e.keyCode === 13) {
				this.saveMood(e)
			}
		}

		const status = MyProfile.data.fortuneTellerStatus
		const statusToString = fortuneTellerStatusToString[status]
		let statusClassName = ''
		switch (status) {
			case fortuneTellerStatus.calling: {
				statusClassName =
					'btn-raised color_white-default spread_width arrow_down'
				break
			}
			case fortuneTellerStatus.chatting: {
				statusClassName =
					'btn-raised color_white-default spread_width arrow_down'
				break
			}
			case fortuneTellerStatus.offline: {
				statusClassName =
					'btn-raised color_accent spread_width arrow_down'
				break
			}
			case fortuneTellerStatus.chattingFull: {
				statusClassName =
					'btn-raised color_white-default spread_width arrow_down'
				break
			}
			default: {
				statusClassName =
					'btn-raised color_default spread_width arrow_down'
				break
			}
		}

		return (
			<div className="secondary-profile" id="js_secondary_profile">
				<Link
					onClick={this.goSetting}
					className="secondary-profile__anchor"
					to="/home/setting"
				>
					<MyProfileImage MyProfile={MyProfile} />
					<p className="secondary-profile__name">
						{MyProfile.data.fortuneTellerName}
					</p>
					{MyProfile.data.point && (
						<p className="secondary-profile__reward">
							報酬:{' '}
							{Number.isInteger(MyProfile.data.point)
								? MyProfile.data.point
								: MyProfile.data.point.toFixed(2)}
							PT
						</p>
					)}
				</Link>
				{/* Current Stage  */}
				{/* {this.state.isExist ? ( */}
				<Link
					to={blogLink}
					className="linkBlog"
					onClick={this.goSetting}
				>
					<p className="secondary-profile__reward stage-name">
						現在のステージ&nbsp;&nbsp;
						<span>
							{MyProfile.data.stageName
								? MyProfile.data.stageName
								: ''}
						</span>
					</p>
				</Link>
				{/* ) : (
            <p
              className='linkBlog'
              style={{ cursor: 'pointer' }}
            >
              <p className='secondary-profile__reward stage-name'>
                現在のステージ&nbsp;&nbsp;
              <span>{MyProfile.data.stageName ? MyProfile.data.stageName : ''}</span>
              </p>
            </p>
          )} */}

				<div className="secondary-profile__status">
					<div className="btn-wrap margin_bottom">
						<button
							onClick={(e) => this.showToggle(e)}
							className={statusClassName}
						>
							{statusToString}
						</button>
						<StatusControl show={this.state.showStatusbox} closeDialog={(e) => this.showToggle(e)} />
					</div>

					<div className="secondary-comment">
						<textarea
							className="secondary-comment__input"
							id="secondary_comment"
							placeholder={isPWA() ? "お客様へのアピールメッセージを記入しましょう" : "お客様の興味を惹くPRメッセージを記入しましょう！"}
							name="mood"
							value={this.state.mood}
							onKeyDown={(e) => moodkeyEvent(e)}
							onChange={(e) => this.handleChange(e)}
							onMouseOver={() => this.handleOpenToolTip()}
							onMouseOut={() => this.handleCloseToolTip()}
							onFocus={() => this.handleFocus()}
							onBlur={() => this.handleBlur()}
						/>
						{this.state.showUpdateButton ? (
							<div className="secondary-comment-edit">
								<button
									onClick={(e) => this.saveMood(e)}
									className="btn secondary-comment-edit__btn"
								>
									更新
								</button>
								<p className="secondary-comment-edit__count">
									{moodLength}
								</p>
							</div>
						) : (
							<div className="icon material-icons secondary-comment__icons">
								mode_edit
							</div>
						)}
					</div>

					{!isPWA() &&
						<div className="link-appeal__mesage" onClick={() => this.popUpAppealMessage()}>
							<span>＞良い記入例のサンプルを見る</span>
						</div>
					}
				</div>
				<DialogAppealMessage
					isShow={this.state.isShowPopupAppealMessage}
					resetToggle={this.resetToggleAppealMessage}
				/>
			</div>
		)
	}
}

const MyProfileImage = ({ MyProfile }) => {
	if (MyProfile.data.avatarPath) {
		if (
			MyProfile.data.avatarStatus == AvatarStatus.PENDDING ||
			MyProfile.data.avatarStatus == AvatarStatus.MODIFY
		) {
			return (
				<figure className="secondary-profile__figure" data-badge="!!">
					<img
						src="/img/spacer.gif"
						style={{
							backgroundImage: `url(${MyProfile.data.avatarPath})`,
						}}
					/>
				</figure>
			)
		}

		if (MyProfile.data.avatarStatus == AvatarStatus.DENY) {
			return (
				<figure className="secondary-profile__figure" data-badge="!!">
					<img
						src="/img/spacer.gif"
						style={{
							backgroundImage: `url(/img/thumb_noimage-2x.png)`,
						}}
					/>
				</figure>
			)
		}
		if (MyProfile.data.avatarPath === `${glasConfig.url_image}/null`) {
			return (
				<figure className="secondary-profile__figure" data-badge="!!">
					<img
						src="/img/spacer.gif"
						style={{
							backgroundImage: `url(/img/thumb_noimage-2x.png)`,
						}}
					/>
				</figure>
			)
		}

		return (
			<figure className="secondary-profile__figure">
				<img
					src="/img/spacer.gif"
					style={{
						backgroundImage: `url(${MyProfile.data.avatarPath})`,
					}}
				/>
			</figure>
		)
	}
	return (
		<figure className="secondary-profile__figure" data-badge="!!">
			<img
				src="/img/spacer.gif"
				style={{ backgroundImage: `url(/img/thumb_noimage-2x.png)` }}
			/>
		</figure>
	)
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(LeftProfile)
