/**
 * 占い師の待機状態
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Fetch from '../util/Fetch'
import * as MyProfileActions from '../actions/MyProfileActions'
import * as MyAccountActions from '../actions/MyAccountActions'
import {
	fortuneTellerStatus,
	fortuneTellerStatusToString,
	LIMIT_CHAT_DEFAULT,
	TELLER_STATUS,
} from '../constants/Config'
import Toggle from 'material-ui/Toggle'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import DialogNotifi from './Dialog'
import { ModalMissingProfile } from './Modals/ModalMissingProfile/ModalMissingProfile'
import { isMobile } from 'react-device-detect'
const DATA = [
	{ value: 8, content: '8人' },
	{ value: 7, content: '7人' },
	{ value: 6, content: '6人' },
	{ value: 5, content: '5人' },
	{ value: 4, content: '4人' },
	{ value: 3, content: '3人' },
	{ value: 2, content: '2人' },
	{ value: 1, content: '1人' },
]

class StatusControl extends Component {
	constructor(props) {
		super(props)
		const toggleDefault = this.getToggleDefault()
		this.state = {
			chat: toggleDefault.chat,
			call: toggleDefault.call,
			saved: true,
			limitChat: this.props.MyProfile.data.maxNumberUserChatting
				? this.props.MyProfile.data.maxNumberUserChatting
				: LIMIT_CHAT_DEFAULT,
			numberChatting: this.getNumberUserChatting(),
			showModalMissingProfile: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.show) {
			this.setState({ saved: true })
		}
	}

	componentWillMount() {
		const { dispatch, MyAccount } = this.props
		dispatch(MyAccountActions.fetch())
	}

	//待機状態からtoggleのdefaultの基本設定を取得する
	getToggleDefault() {
		const { MyProfile } = this.props
		const status = MyProfile.data.fortuneTellerStatus

		// chat: チャット待機、両待機であればtrueを返し、defaultで選択されている状態となる
		// call: 通話待機、両待機であればtrueを返し、defaultで選択されている状態となる
		// disabled : 通話中やチャット中のステータスであれば変更できないように、trueを返します。trueで変更不可、falseで変更可
		// status: 「待機中、対応中、オフライン」ステータスから状態を返します。

		switch (status) {
			case fortuneTellerStatus.waiting: {
				return {
					chat: true,
					call: true,
					disabled: false,
					status: 'waiting',
				}
			}
			case fortuneTellerStatus.chat: {
				return {
					chat: true,
					call: false,
					disabled: false,
					status: 'waiting',
				}
			}
			case fortuneTellerStatus.call: {
				return {
					chat: false,
					call: true,
					disabled: false,
					status: 'waiting',
				}
			}
			case fortuneTellerStatus.chatting: {
				return {
					chat: false,
					call: false,
					disabled: true,
					status: 'busy',
				}
			}
			case fortuneTellerStatus.calling: {
				return {
					chat: false,
					call: false,
					disabled: true,
					status: 'busy',
				}
			}
			case fortuneTellerStatus.offline: {
				return {
					chat: false,
					call: false,
					disabled: false,
					status: 'offline',
				}
			}
			case fortuneTellerStatus.chattingFull: {
				return {
					chat: false,
					call: false,
					disabled: true,
					status: 'busy',
				}
			}
			default: {
				return {
					chat: false,
					call: false,
					disabled: false,
					status: 'busy',
				}
			}
		}
	}

	//チャット、通話の選択状況によってAPIにリクエストする値を取得する
	//メソッド内でstateから取得しない理由は、setStateのあとにstateが即時に反映されないためです
	getStatusByToggle(chat, call) {
		if (chat && call) {
			return fortuneTellerStatus.waiting
		}
		if (chat && !call) {
			return fortuneTellerStatus.chat
		}
		if (!chat && call) {
			return fortuneTellerStatus.call
		}
		return fortuneTellerStatus.offline
	}

	handleValidateOnToggle = (e) => {
		const { MyProfile } = this.props
		// if (MyProfile.data.isAllowChangeStatus === false) {
		// 	// dont allow
		// 	this.setState({ showModalMissingProfile: true })
		// } else {
		this.handleChange(e)
		// }
	}

	resetToggle = () => {
		console.log('%c resetToggle: ', 'color: red')
		this.setState({
			showModalMissingProfile: false,
		})
	}

	//toggleの状態が変更されたときに実行される
	handleChange(e) {
		const key = e.target.name
		const value = e.target.checked
		const { MyAccount } = this.props

		this.setState({
			[key]: value,
			saved: false,
		})

		const toggleDefault = this.getToggleDefault()

		//今現在、待機状態であればtoggleの値が変更された時に即時反映。待機ボタンを押さない
		if (toggleDefault.status === 'waiting') {
			let chat = null
			let call = null
			if (key === 'chat') {
				chat = value
				call = this.state.call
			} else if (key === 'call') {
				chat = this.state.chat
				call = value
			}
			if (chat !== null && call !== null) {
				this.doChangeStatus(this.getStatusByToggle(chat, call))
			}
		}
	}

	//待機、オフラインどちらかのボタンが押された時に実行されます
	handleChangeStatus(e, type) {
		const { MyProfile, MyAccount } = this.props
		e.stopPropagation()
		if (!(Fetch.isAgeVerify(MyProfile.data.verifyAge) && MyProfile.data.isAllowChangeStatus && (MyProfile.data.firstTimeAssignedAgent || MyProfile.data.isAttendedMeeting))) {
			DialogNotifi.getDialog().handleOpen('VALIDATE_WAITING')
			this.props.closeDialog()
			return
		}

		const toggleDefault = this.getToggleDefault()

		if (type === 'offline') {
			if (
				toggleDefault.status === 'busy' ||
				toggleDefault.status === 'offline'
			) {
				//オフラインや通話中などの時にオフラインボタンを押されたときは無視
				return
			}
			this.setState({
				chat: false,
				call: false,
			})
			this.doChangeStatus(fortuneTellerStatus.offline)
		} else if (type === 'wait') {
			this.doChangeStatus(
				this.getStatusByToggle(this.state.chat, this.state.call)
			)
			window.addEventListener('beforeunload', (ev) => {
				ev.preventDefault()
				return (ev.returnValue = 'Are you sure you want to close?')
			})
		}
		this.setState({ saved: true })
	}

	//glasAPIにリクエスト
	doChangeStatus(status) {
		const { dispatch, MyProfile } = this.props
		const data = MyProfile.data
		data['fortuneTellerStatus'] = status
		data['maxNumberUserChatting'] = this.state.limitChat
		dispatch(MyProfileActions.put('status', data))
	}

	getNumberUserChatting() {
		const { MyProfile } = this.props
		const maxNumberChatting = MyProfile.data.maxNumberUserChatting
		const remainNumberChatting = MyProfile.data.remainChatNumber
		return maxNumberChatting - remainNumberChatting
	}

	changeLimitUserWhileChatting = (limit) => {
		const { dispatch } = this.props
		console.log('%c CHANGE LIMIT WHILE CHATTING', 'color: red')
		dispatch(MyProfileActions.changeLimitUserChatting(limit))
	}

	onChangeLimitChat = (event, index, value) => {
		const status = this.props.MyProfile.data.fortuneTellerStatus
		const numberChat = this.getNumberUserChatting()
		console.log('numberChat', numberChat)
		this.setState(
			{
				limitChat: value,
				numberChatting: numberChat,
			},
			() => {
				if (value < this.state.numberChatting) return
				if (this.state.numberChatting !== 0) {
					// NEW API
					this.changeLimitUserWhileChatting(value)
				} else {
					const chat = this.state.chat
					const call = this.state.call
					if (status === TELLER_STATUS.OFFLINE) {
						this.doChangeStatus(this.getStatusByToggle(chat, call))
					} else {
						this.changeLimitUserWhileChatting(value)
					}
					// const chat = this.state.chat
					// const call = this.state.call
					// if (chat !== null && call !== null) {
					//   this.doChangeStatus(this.getStatusByToggle(chat, call))
					// } else {
					//   const { MyProfile } = this.props;
					//   this.doChangeStatus(MyProfile.data.fortuneTellerStatus)
					// }
				}
			}
		)
	}

	renderItem = (numberChat) => {
		return DATA.map((item, key) => (
			<MenuItem
				key={key}
				disabled={item.value < numberChat}
				value={item.value}
				primaryText={item.content}
			// innerDivStyle={{ padding: 0, textAlign: 'center' }}
			/>
		))
	}

	render() {
		if (!this.props.show) {
			return null
		}
		const { MyProfile, MyAccount } = this.props
		const status = MyProfile.data.fortuneTellerStatus
		const numberChat = this.getNumberUserChatting()
		const toggleDefault = this.getToggleDefault()

		//待機ボタンの表示について
		let StatusChangeButton = ''
		switch (toggleDefault.status) {
			case 'waiting': {
				StatusChangeButton = (
					<button
						className="btn-raised color_accsent spread_width"
						onClick={(e) => this.handleChangeStatus(e, 'offline')}
					>
						オフラインにする
					</button>
				)
				break
			}
			case 'offline': {
				StatusChangeButton = (
					<button
						className="btn-raised color_default spread_width"
						disabled={
							this.state.saved ||
							!(this.state.call || this.state.chat)
						}
						onClick={(e) => this.handleChangeStatus(e, 'wait')}
					>
						待機する
					</button>
				)
				break
			}
			case 'busy': {
				StatusChangeButton = (
					<p className="secondary-workstart-btn__text">
						{
							fortuneTellerStatusToString[
							MyProfile.data.fortuneTellerStatus
							]
						}
						<br />
						はステータスを変更できません
					</p>
				)
				break
			}
		}
		return (
			<div>
				<div className="secondary-workstart-wrap">
					<div className="secondary-workstart">
						<div className="secondary-workstart-btn">
							{StatusChangeButton}
						</div>
						<div className="secondary-workstart-toggle">
							<Toggle
								label="チャット待機"
								name="chat"
								defaultToggled={toggleDefault.chat}
								disabled={toggleDefault.disabled}
								toggle={String(this.state.chat)}
								toggled={this.state.chat}
								onToggle={(e) => this.handleValidateOnToggle(e)}
							/>
						</div>
						{(this.state.chat ||
							toggleDefault.chat ||
							(toggleDefault.status == 'busy' &&
								(MyProfile.data.fortuneTellerStatus ==
									fortuneTellerStatus.chatting ||
									MyProfile.data.fortuneTellerStatus ==
									fortuneTellerStatus.chattingFull))) && (
								<div
									className="secondary-workstart-toggle"
									style={{ paddingTop: 0, paddingBottom: 0 }}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'baseline',
										}}
									>
										<label
											style={{
												flex: 3,
												lineHeight: '24px',
												color: 'rgba(0, 0, 0, 0.87)',
												fontFamily: 'Roboto, sans-serif',
												marginRight: 10,
											}}
										>
											同時鑑定
										</label>
										{
											// ! toggleDefault.disabled &&
											<SelectField
												onChange={this.onChangeLimitChat}
												value={this.state.limitChat}
												className="select_number_chat"
												// disabled={ status === 6 }
												dropDownMenuProps={
													{
														// style: { width: 50 }
													}
												}
												underlineStyle={{ top: '40px' }} // check width
												style={{
													flex: 1,
													top: '4px',
													fontSize: '15px',
													width: 60,
												}}
											>
												{this.renderItem(numberChat)}
											</SelectField>
										}
										{!toggleDefault.disabled && (
											<div
												style={{
													flex: 1,
													whiteSpace: 'nowrap',
												}}
											>
												まで
											</div>
										)}

										{toggleDefault.disabled && (
											<div
												style={{
													flex: 1,
													color: '#000',
													whiteSpace: 'nowrap',
												}}
											>
												まで
											</div>
										)}
									</div>
								</div>
							)}
						<div className="secondary-workstart-toggle">
							<Toggle
								label="通話相談"
								name="call"
								defaultToggled={toggleDefault.call}
								disabled={toggleDefault.disabled}
								toggle={String(this.state.call)}
								toggled={this.state.call}
								onToggle={(e) => this.handleValidateOnToggle(e)}
							/>
						</div>
						{/* {this.state.call && isMobile &&	<div className="alert-advise-call">通話待機中にアプリをバックグラウンドからタスクキルしないでください</div>} */}
					</div>
				</div>
				<ModalMissingProfile
					show={this.state.showModalMissingProfile}
					resetToggle={this.resetToggle}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
		MyAccount: state.MyAccount,
		ChatRequest: state.ChatRequest,
	}
}

export default connect(mapStateToProps)(StatusControl)
