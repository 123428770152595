import {
	CONTACT_GET_DETAIL,
	CONTACT_GET_HISTORY,
	CONTACT_POST_QUESTION,
	CONTACT_RESOLVE_QUESTION,
	CONTACT_GET_UNREAD,
	CONTACT_GET_LIST_TIME,
	MYINFO_PROFILE,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}
export const getContactHistory = (params, page) => {
	return (dispatch) => {

		let urlPost =
			'http://api2.local.stella-app.com:8080' +
			glasConfig.path_contact +
			params
		let linkGet = glasConfig.url_outside + glasConfig.path_contact + params
		// http://api2.local.stella-app.com:8080/contact?userId=5ab9ecac42010172dcc39e4d&size=10&page=0
		// https://api2.dev.stella-app.com/contact?userId=5ab9ecac42010172dcc39e4d&size=10&page=0
		return request
			.get(linkGet)
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						data: response.data.data,
						page: page || 0,
					}
					dispatch(resultSuccess(CONTACT_GET_HISTORY, res))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + CONTACT_GET_HISTORY, error)
				throw error
			})
	}
}

export const getContactUnread = (param) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_outside + glasConfig.path_contact + param)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(CONTACT_GET_UNREAD, response.data.data)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}
}

export const getDetailThread = (params) => {
	return (dispatch) => {
		return request
			.get(
				glasConfig.url_outside +
				glasConfig.path_contact +
				`/detail?threadId=${params.threadId}&userId=${params.userId}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						data: response.data.data,
					}
					dispatch(resultSuccess(CONTACT_GET_DETAIL, res))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + CONTACT_GET_DETAIL, error)
				throw error
			})
	}
}

export const postQuestion = (params) => {
	return (dispatch) => {
		let linkPost =
			glasConfig.url_outside + glasConfig.path_contact + '/postQuestion'
		request
			.post(linkPost, params, {
				headers: {
					Authorization: localStorage.getItem('token'),
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(CONTACT_POST_QUESTION, response.data.data)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + CONTACT_POST_QUESTION, error)
				throw error
			})
	}
}

// タイムラインファイル同時登録
export const postFileQuestion = (params) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		Promise.resolve()
			.then(() => {
				return new Promise((resolve, reject) => {
					request
						.post(
							glasConfig.url_base + glasConfig.path_file,
							params.fileData,
							{
								headers: {
									Authorization: token,
								},
							}
						)
						.then((response) => {
							if (response.data.code === 0) {
								resolve(response.data.data.filePath)
							} else {
								throw 'システムエラー'
							}
						})
						.catch((error) => {
							console.log(
								'error on call ' + CONTACT_POST_QUESTION,
								error
							)
							throw error
						})
				})
			})
			.then((filePath) => {
				let body = {}
				body.filePath = filePath
				body.body = params.body
				body.userId = params.userId
				body.userName = params.userName
				body.reply = params.reply
				body.threadId = params.threadId
				body.subject = params.subject
				body.content = params.content
				request
					.post(
						glasConfig.url_outside +
						glasConfig.path_contact +
						'/postQuestion',
						body,
						{
							headers: {
								Authorization: localStorage.getItem('token'),
								'Content-Type': 'application/json',
							},
						}
					)
					.then((response) => {
						if (response.data.code === 0) {
							dispatch(
								resultSuccess(
									CONTACT_POST_QUESTION,
									response.data.data
								)
							)
						} else {
							throw 'システムエラー'
						}
					})
					.catch((error) => {
						console.log(
							'error on call ' + CONTACT_POST_QUESTION,
							error
						)
						throw error
					})
			})
	}
}

export const resolveQuestion = (params) => {
	return (dispatch) => {
		request
			.post(
				glasConfig.url_outside +
				glasConfig.path_contact +
				'/resolveQuestion',
				params,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
						'Content-Type': 'application/json',
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(
							CONTACT_RESOLVE_QUESTION,
							response.data.data
						)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + CONTACT_RESOLVE_QUESTION, error)
				throw error
			})
	}
}

export const getListTimeValidateUser = () => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_outside + glasConfig.get_list_time_validate_user)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(CONTACT_GET_LIST_TIME, response.data.data)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}
}

export const updateAttendedMeeting = (tellerInfo) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.update_attended_meeting

		const options = {
			method: 'PUT',
			url: url,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					if (response.data.data) {
						const data = { ...tellerInfo, isAttendedMeeting: true }
						dispatch(resultSuccess(MYINFO_PROFILE, data))
					}
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}
}
