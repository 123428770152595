import React, { Component } from 'react'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { connect } from 'react-redux'
import FormBug from './Contact/FormBug'
import FormOther from './Contact/FormOther'
import PreviewBug from './Contact/PreviewBug'
import ContactMenu from './ContactMenu'
import { isMobile } from 'react-device-detect'
import FormValidateTeller from './Contact/FormValidateTeller'
import * as ContactActions from '../actions/ContactActions'

let options = [
	{ value: 0, label: '選択してください' },
	{ value: 1, label: '不具合' },
	{ value: 2, label: '質問' },
	{ value: 3, label: '要望' },
	{ value: 4, label: 'その他' },
	{ value: 5, label: '[新人向け] Stella説明会' },
]
class ContactCreate extends Component {
	constructor(props) {
		const type = !!props.location.state ? props.location.state.type : 0
		super(props)
		this.state = {
			loading: false,
			category: options[type],
			stepCategory: 0,
			data: {}
		}
	}

	componentWillMount() {
		const { dispatch, MyProfile } = this.props
		dispatch(ContactActions.getListTimeValidateUser())
		if (MyProfile.data.firstTimeAssignedAgent) {
			options = options.slice(0, 5)
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.Contact.post.data !== this.props.Contact.post.data) {
			localStorage.setItem('contact', '')
			window.location.pathname = `/home/contact/detail/${this.props.Contact.post.data}`
		}
	}

	render() {
		return (
			<div className="content contactContent">
				<h1 className="content__title hidden-sp">お問い合わせ</h1>
				<ContactMenu />

				<div className="primary contactPrimary" hidden={this.state.stepCategory === 0 ? false : true}>
					<div className="primary-title hidden-sp">
						<h1 className="primary-title__main">
							お問い合わせ窓口
						</h1>
					</div>
					<p className="titleTextBox" style={{ marginTop: isMobile ? 8 : 47 }}>お問い合わせ項目</p>
					<Dropdown
						className="dropdown-contact"
						options={options} onChange={(e) => this.setState({ category: e })} value={this.state.category} placeholder="Select an option" />
					{
						this.state.category.value !== 0 &&
						(
							this.state.category.value === 1 ?
								<FormBug onChangeStepCategory={(stepCategory, data) => this.setState({ stepCategory, data })} />
								:
								this.state.category.value === 5 ?
									<FormValidateTeller onChangeStepCategory={(stepCategory, data) => this.setState({ stepCategory, data })} />
									:
									<FormOther onChangeStepCategory={(stepCategory, data) => this.setState({ stepCategory, data })} />
						)
					}
				</div>



				<div hidden={this.state.stepCategory !== 0 ? false : true}>
					<PreviewBug onChangeStepCategory={(data) => this.setState({ stepCategory: data })} data={{ ...this.state.data, category: this.state.category }} />
				</div>

			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		MyProfile: store.MyProfile,
		Contact: store.Contact,
	}
}

export default connect(mapStateToProps)(ContactCreate)
