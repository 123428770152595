import React, { useState, useEffect, useMemo } from 'react'

import SwipeableViews from 'react-swipeable-views'
import { connect } from 'react-redux'
import { ScreenName, StyledContainerMainContent } from '../PointReport/StyledPointReport'
import CustomTabs from '../PointReport/Frames/Common/Tabs'
import { EVENT_TABS } from '../../constants/Config'
import CustomTab from '../PointReport/Frames/Common/Tab'
import { TabPanel } from '../PointReport/Frames/Common/TabPanel'
import EventRankingList from './Tabs/EventRankingList'
import EventSchedule from './Tabs/EventSchedule'

const POIN_REPORT_MENU = {
    EVENT_SCHEDULE: 0,
    RANKING_EVENT: 1
}

const MainEventTab = props => {
    const [value, setValue] = useState(POIN_REPORT_MENU.EVENT_SCHEDULE)
    useEffect(() => {
        if (props.history.location.state) {
            setValue(props.history.location.state.index)
            return
        }
        if (props.history.location.params) {
            setValue(props.history.location.params.index)
            return
        }
        else {
            setValue(POIN_REPORT_MENU.EVENT_SCHEDULE)
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    return (
        <>
            <StyledContainerMainContent>
                <ScreenName>イベント</ScreenName>
                <CustomTabs
                    value={value}
                    onChange={handleChange}
                >
                    {EVENT_TABS.map((tab, index) => <CustomTab key={index} label={tab.label} />)}
                </CustomTabs>
                <SwipeableViews
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={POIN_REPORT_MENU.EVENT_SCHEDULE}>
                        {
                            <EventSchedule />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={POIN_REPORT_MENU.RANKING_EVENT}>
                        {
                            <EventRankingList />
                        }
                    </TabPanel>
                </SwipeableViews>
            </StyledContainerMainContent>

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        TellerProfile: state.MyProfile,
        PointReportData: state.PointReport,
    }
}

export default connect(mapStateToProps)(MainEventTab)